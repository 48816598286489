import { render, staticRenderFns } from "./Criciuma.vue?vue&type=template&id=624cfc6b&scoped=true&"
import script from "./Criciuma.vue?vue&type=script&lang=js&"
export * from "./Criciuma.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624cfc6b",
  null
  
)

export default component.exports